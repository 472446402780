import React from 'react'

function Singin() {
  return (
    <div className='pt-24'>
      Sigin
    </div>
  )
}

export default Singin
